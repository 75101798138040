<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item"><b-link class="nav-link"  :to="{ name: 'home' }">Appels</b-link></li>
      <li class="nav-item"><b-link class="nav-link"  :to="{ name: 'library' }">Librairie</b-link></li>
      <li class="nav-item"><b-link class="nav-link"  :to="{ name: 'users' }">Utilisateurs</b-link></li>
      <li class="nav-item"><b-link class="nav-link"  :to="{ name: 'statistiques' }">Statistiques</b-link></li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <b-link class="nav-link" :to="{ name: 'home' }">Appels</b-link>
      <b-link class="nav-link" :to="{ name: 'library' }">Librairie</b-link>
      <b-link class="nav-link" :to="{ name: 'users' }">Utilisateurs</b-link>
      <b-link class="nav-link" :to="{ name: 'statistiques' }">Statistiques</b-link>

    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{user.prenom}} {{user.nom}}
            </p>
            <span class="user-status">{{user.team}}</span>
          </div>
          <!--<b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />-->
        </template>
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span v-on:click="logout">Déconnexion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    NotificationDropdown,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: {}
    }
  },
  mounted() {
    if (localStorage.getItem("user") === null) {
      this.$router.push({name:"login"})
    } else {
      this.user = JSON.parse(localStorage.getItem("user"))
      if ("token" in this.user) {
        this.$http
            .post($themeConfig.app.restEndPoint +"/checktoken", {
              token: this.user["token"]
            })
            .then((response) => {
              var checktoken = response.data
              if (checktoken["result"] == false) {
                localStorage.removeItem("user")
                this.$router.push({name:"login"})
              }
            })
      } else {
        localStorage.removeItem("user")
        this.$router.push({name:"login"})
      }
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("user")
      this.$router.push({name:"login"})
    }
  }
}
</script>
