<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    :active="true"
    v-if="notifications.list"
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.unread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{notifications.unread}} nouveaux
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications.list"
        :key="notification.index"
        v-on:click="open_call(notification)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="require('@/assets/images/avatars/6-small.png')"
              :text="require('@/assets/images/avatars/6-small.png')"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <!--<li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Marquer toutes comme lues</b-button>
    </li>-->
  </b-nav-item-dropdown>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  methods: {
    open_call(notification) {
      
      this.$http
        .post($themeConfig.app.restEndPoint + "/notification/read", {"id_notify": notification.id_notify})
        .then((response) => {
          this.get_notifications()
          this.$router.push({ name: 'call', params: { id_call: notification.id_call }})
        });
      
    },
    get_notifications() {
      var user = JSON.parse(localStorage.getItem("user"))
      this.$http
          .post($themeConfig.app.restEndPoint + "/notification", {"id_user": user.id_user})
          .then((response) => {
            var unread = 0
            this.$set(this.notifications, "list", response.data.notifications)
            for (var i = 0; i < this.notifications.list.length; i++)
              if (this.notifications.list[i]["read"] == 0)
                unread++
            this.$set(this.notifications, "unread", unread)
          });
    }
  },
  mounted() {
    this.get_notifications()
  },
  setup() {
    /* eslint-disable global-require */ 
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      notifications: {
        list: null,
        unread: 0
      }
    }
  }
}
</script>

<style>

</style>
